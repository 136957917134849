.map {
    overflow: hidden;
    position: relative;
    border: 1px solid #ccc;
    background: #fff
}

.svglabels {
    opacity: 1;
    transition: opacity .3s
}

.svglabels.hide {
    opacity: 0
}

.seats {
    opacity: 1;
    transition: opacity .3s
}

.seats.hide {
    opacity: 0
}

.map__clip {
    overflow: hidden
}

.map__clip:focus {
    outline: none
}

.map__svg,.map__svg--static {
    background: 50% no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: absolute
}

.map__svg--static .block,.map__svg--static .block.is-ga {
    cursor: auto
}

.map__section.is-selected {
    stroke: #999;
    stroke-width: 2px;
    stroke-opacity: .3
}

.seat {
    transition: fill .5s,stroke .5s;
    fill: #ccc;
    fill-rule: evenodd
}

.seat.is-custom path {
    fill: #fff
}

.seat.is-blocked {
    fill: transparent;
    stroke: #bfbfbf
}

.seat.is-available {
    cursor: pointer;
    fill: #009cde
}

.seat.is-available.is-active,.seat.is-available.is-hover,.seat.is-available.is-selected {
    fill: #d0006f
}

.block {
    transition: opacity .5s,fill .5s;
    opacity: .75;
    stroke: #fff;
    stroke-width: 8px
}

.block,.block.is-available {
    fill: #ccc
}

.block.showseats {
    opacity: .15;
    fill: #fff
}

.block.is-ga {
    cursor: pointer
}

.block.is-multiselected {
    fill: #475058
}

.label {
    font-family: Averta,TM Sans,arial,lucida console,sans-serif;
    pointer-events: none;
    fill: #fff
}

.label tspan {
    text-anchor: middle
}

.label.showseats {
    display: none
}

.outerLine circle {
    fill: green
}

.outerLine path {
    stroke: green
}

.innerLine circle {
    fill: #fff
}

.innerLine path {
    stroke: #fff
}

.icon--pin__shadow {
    opacity: .5
}

.icon--pin {
    fill: #19ab20
}

.icon--pin__border {
    fill: #fff
}

.sim-map-tooltip {
    transition: opacity 0s;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    padding: 5px;
    pointer-events: none;
    opacity: 0;
    z-index: -1
}

.sim-map-tooltip.is-active {
    transition: opacity .3s;
    opacity: 1;
    z-index: 5
}

.sim-map-tooltip.is-changing {
    transition: opacity 0s;
    opacity: 0;
    z-index: -1
}

.sim-map-tooltip.above {
    padding: 0 5px 10px
}

.sim-map-tooltip.left {
    padding: 5px 10px 5px 0
}

.sim-map-tooltip.right {
    padding: 5px 0 5px 10px
}

.sim-map-tooltip.below {
    padding: 10px 5px 0
}

.sim-map-tooltip.bottom-right {
    padding: 10px 0 0 10px
}

.sim-map-tooltip.bottom-left {
    padding: 10px 10px 0 0
}

.sim-map-tooltip.top-right {
    padding: 0 0 10px 10px
}

.sim-map-tooltip.top-left {
    padding: 0 10px 10px 0
}

.map-tooltip__content {
    width: 100%;
    height: 100%;
    border: 1px #ccc;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.5)
}

.above .map-tooltip__content:after {
    border-top: 10px solid #fff;
    bottom: 1px
}

.above .map-tooltip__content:after,.below .map-tooltip__content:after {
    width: 0;
    height: 0;
    pointer-events: none;
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -10px
}

.below .map-tooltip__content:after {
    border-bottom: 10px solid #fff;
    top: 0
}

.left .map-tooltip__content:after {
    border-left: 10px solid #fff;
    right: 0
}

.left .map-tooltip__content:after,.right .map-tooltip__content:after {
    width: 0;
    height: 0;
    pointer-events: none;
    border-top: 10px inset transparent;
    border-bottom: 10px inset transparent;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -10px
}

.right .map-tooltip__content:after {
    border-right: 10px solid #fff;
    left: 0
}

.top-left .map-tooltip__content:after {
    transform: rotate(-45deg);
    right: 0
}

.top-left .map-tooltip__content:after,.top-right .map-tooltip__content:after {
    pointer-events: none;
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-top: 20px solid #fff;
    position: absolute;
    content: "";
    z-index: 0;
    bottom: 0
}

.top-right .map-tooltip__content:after {
    transform: rotate(45deg);
    left: 0
}

.bottom-left .map-tooltip__content:after {
    transform: rotate(-135deg);
    right: 0
}

.bottom-left .map-tooltip__content:after,.bottom-right .map-tooltip__content:after {
    pointer-events: none;
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-top: 20px solid #fff;
    position: absolute;
    content: "";
    z-index: 0;
    top: 0
}

.bottom-right .map-tooltip__content:after {
    transform: rotate(135deg);
    left: 0
}

.bordered.below .map-tooltip__content:after,.bordered.bottom-left .map-tooltip__content:after,.bordered.bottom-right .map-tooltip__content:after {
    top: 2px
}

.bordered.bottom-left .map-tooltip__content:after,.bordered.left .map-tooltip__content:after,.bordered.top-left .map-tooltip__content:after {
    right: 2px
}

.bordered.above .map-tooltip__content:after,.bordered.top-left .map-tooltip__content:after,.bordered.top-right .map-tooltip__content:after {
    bottom: 2px
}

.bordered.bottom-right .map-tooltip__content:after,.bordered.right .map-tooltip__content:after,.bordered.top-right .map-tooltip__content:after {
    left: 2px
}

.bordered.above .map-tooltip__content:before {
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-top: 10px solid;
    border-top-color: inherit;
    bottom: 0
}

.bordered.above .map-tooltip__content:before,.bordered.below .map-tooltip__content:before {
    width: 0;
    height: 0;
    pointer-events: none;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -10px
}

.bordered.below .map-tooltip__content:before {
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-bottom: 10px solid;
    border-bottom-color: inherit;
    top: 0
}

.bordered.left .map-tooltip__content:before {
    border-top: 10px inset transparent;
    border-bottom: 10px inset transparent;
    border-left: 10px solid;
    border-left-color: inherit;
    right: 0
}

.bordered.left .map-tooltip__content:before,.bordered.right .map-tooltip__content:before {
    width: 0;
    height: 0;
    pointer-events: none;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -10px
}

.bordered.right .map-tooltip__content:before {
    border-top: 10px inset transparent;
    border-bottom: 10px inset transparent;
    border-right: 10px solid;
    border-right-color: inherit;
    left: 0
}

.bordered.top-left .map-tooltip__content:before {
    transform: rotate(-45deg);
    right: 0
}

.bordered.top-left .map-tooltip__content:before,.bordered.top-right .map-tooltip__content:before {
    pointer-events: none;
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-top: 20px solid;
    border-top-color: inherit;
    position: absolute;
    content: "";
    z-index: 0;
    bottom: 0
}

.bordered.top-right .map-tooltip__content:before {
    transform: rotate(45deg);
    left: 0
}

.bordered.bottom-left .map-tooltip__content:before {
    transform: rotate(-135deg);
    right: 0
}

.bordered.bottom-left .map-tooltip__content:before,.bordered.bottom-right .map-tooltip__content:before {
    pointer-events: none;
    border-left: 10px inset transparent;
    border-right: 10px inset transparent;
    border-top: 20px solid;
    border-top-color: inherit;
    position: absolute;
    content: "";
    z-index: 0;
    top: 0
}

.bordered.bottom-right .map-tooltip__content:before {
    transform: rotate(135deg);
    left: 0
}

.is-anchored .map-tooltip__content {
    pointer-events: auto
}

.bordered .map-tooltip__content {
    border: 1px solid
}

.zoomer {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none
}

.map__zoomer {
    cursor: grab;
    outline: 0;
    transform-origin: 0 0;
    will-change: transform,width,height
}

.map__zoomer.is-active {
    cursor: grabbing
}

.zoomer__controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1
}

.zoomer__control--reset,.zoomer__control--zoomin,.zoomer__control--zoomout {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 0 5px;
    border: 1px solid #ccc;
    background: #fff
}

.minimap {
    transition: opacity .5s;
    transform: perspective(0);
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 25%;
    height: 25%;
    overflow: hidden;
    pointer-events: none;
    border: 1px solid #ccc;
    opacity: 0;
    background-color: #fff
}

.minimap.is-active {
    opacity: 1
}

.minimap__eyeglass {
    display: none;
    position: absolute;
    border: 2px solid #d0006f;
    opacity: .8;
    background-color: #d0006f
}

.minimap__eyeglass.is-active {
    display: block
}


.jUfklg {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(235, 235, 235);
}

.eORvlx {
    position: relative;
    width: auto;
    height: 100%;
    overflow: hidden;
    flex: 1 1 0%;
}


.st0{fill:#95C55C;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st1{fill:#DCD4B8;}
.st2{fill:#264C96;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st3{fill:#575756;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st4{fill:#97E14E;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st5{fill:#417D45;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st6{fill:#77AF35;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st7{fill:#427F46;stroke:#FFFFFF;stroke-width:7;stroke-miterlimit:10;}
.st8{display:none;}
.st9{display:inline;}
.st10{fill:#FFFFFF;}

.showseats .st0{
    stroke:#DDDDDD;
}

.showseats .st2{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}
.showseats .st3{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}
.showseats .st4{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}
.showseats .st5{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}
.showseats .st6{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}

.showseats .st7{
    fill:#FFFFFF;
    stroke:#DDDDDD;
}

.showseats .st8{
    display: inline;
}

.showseats .st10{
    fill: rgb(42, 85, 217);
}

.viewer-container:not(.showseats) .st2:hover,
.viewer-container:not(.showseats) .st3:hover,
.viewer-container:not(.showseats) .st4:hover,
.viewer-container:not(.showseats) .st5:hover,
.viewer-container:not(.showseats) .st6:hover,
.viewer-container:not(.showseats) .st7:hover{
    cursor: pointer;
    stroke-width: 2;
}